import moment from 'moment';
import { DISPLAY_DATE_FORMAT, SERVICE_PORTAL_ANONYMOUS } from '../../../common/constants';
import { ga4Event } from '../../../../utils/google-analytics-4';
import { merge } from 'lodash';
import { setPaymentInstrument } from '../makePaymentAnonymousReducer';

export const configurePaymentBody = (
  instrumentToPayWith,
  amountToPay,
  customerId,
  accountId,
  paymentServiceAccountId,
) => {
  let finalBody;
  if (instrumentToPayWith.sourceType === 'CreditCard') {
    finalBody = {
      scheduledDate: moment().format(DISPLAY_DATE_FORMAT),
      paymentMethod: 'Credit Card',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          electronicPaymentSource: {
            creditCardNumber: instrumentToPayWith.lastFour,
            customerPaymentProfileId: instrumentToPayWith.id.toString(),
            customerProfileId: `CUST-${customerId}`,
            paymentServiceAccountId: paymentServiceAccountId,
            sourceName: SERVICE_PORTAL_ANONYMOUS,
          },
        },
      ],
    };
  } else {
    finalBody = {
      scheduledDate: moment().format(DISPLAY_DATE_FORMAT),
      paymentMethod: 'ACH',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          electronicPaymentSource: {
            achBankAccountNumber: instrumentToPayWith.achBankAccountNumber,
            achBankAccountTypeCd: instrumentToPayWith.achBankAccountTypeCd,
            achName: instrumentToPayWith.achName,
            achRoutingNumber: instrumentToPayWith.achRoutingNumber,
            achStandardEntryClassCd: instrumentToPayWith.achStandardEntryClassCd,
            customerPaymentProfileId: instrumentToPayWith?.id
              ? instrumentToPayWith.id.toString()
              : '',
            sourceName: SERVICE_PORTAL_ANONYMOUS,
            paymentServiceAccountId: paymentServiceAccountId,
          },
        },
      ],
    };
  }

  if (instrumentToPayWith.isTemporary) {
    // GA4 event for ACH-Account/CreditCard added
    ga4Event({
      category: 'make-a-payment',
      action:
        instrumentToPayWith.sourceType === 'CreditCard' ? 'credit_card_added' : 'ach_account_added',
      label: 'anonymous_payment',
    });
  }
  return finalBody;
};

export const makeSDK = (
  firstName,
  lastName,
  customerAddress,
  paymentTenantName,
  paymentConfigId,
  paymentApiUrl,
  paymentCategory,
) => {
  let config = {
    apiUrl: paymentApiUrl,
    tenant: paymentTenantName,
    paymentConfigId: paymentConfigId,
    paymentCategory: paymentCategory,
    featurePrefillAddress: true,
    featureCustomAddressCollection: true,
    dev_enableVerboseLogging: false,
    customerAddress: customerAddress.addr1,
    customerCountry: 'US',
    customerCity: customerAddress.city,
    customerFirstName: firstName,
    customerLastName: lastName,
    customerState: customerAddress.stateProvCd,
    customerZip: customerAddress.postalCode,
  };
  console.log(config);
  return window.GuidewirePayments.createSDK(config);
};
const addFundingInstrumentTemporarily = (
  response,
  setFundingInstruments,
  fundingInstruments,
  dispatch,
  isUpdatingPaySchedule,
  tokenizedPaymentSource,
) => {
  let fundingInstrument = merge({}, response, tokenizedPaymentSource);
  fundingInstrument.isTemporary = true;
  fundingInstrument.achBankAccountTypeCd = fundingInstrument?.achBankAccountTypeCd
    ? fundingInstrument?.achBankAccountTypeCd
    : fundingInstrument.accountType
    ? fundingInstrument.accountType
    : '';
  fundingInstrument.achBankAccountNumber = fundingInstrument.achBankAccountNumber
    ? fundingInstrument.achBankAccountNumber
    : fundingInstrument.lastFour
    ? fundingInstrument.lastFour
    : '';
  if (!isUpdatingPaySchedule) {
    setFundingInstruments(
      fundingInstruments ? [...fundingInstruments, fundingInstrument] : [fundingInstrument],
    );
    dispatch(setPaymentInstrument(fundingInstrument));
  } else {
    setFundingInstruments([fundingInstrument]);
    dispatch(setPaymentInstrument(fundingInstrument));
  }
};

export const addPayment = (
  setFundingInstruments,
  fundingInstruments,
  isUpdatingPaySchedule,
  notify,
) => {
  return (dispatch) => {
    window.GuidewirePayments.collectAndAssociateCard(function (result) {
      switch (result.type) {
        case 'ERROR':
          notify(
            'We cannot add your credit card at this time. Please try again later or contact your agent.',
            {
              variant: 'error',
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'top',
              },
            },
          );
          break;
        case 'CARD_ADDED':
          addFundingInstrumentTemporarily(
            result.card,
            setFundingInstruments,
            fundingInstruments,
            dispatch,
            isUpdatingPaySchedule,
            result.tokenizedCard,
          ); //saves if payment is successful
          break;
        case 'PAYMENTSOURCE_ADDED':
          addFundingInstrumentTemporarily(
            result.paymentSource,
            setFundingInstruments,
            fundingInstruments,
            dispatch,
            isUpdatingPaySchedule,
            result.tokenizedPaymentSource,
          ); //saves if payment is successful
          break;
        case 'CANCELED':
          //   vm.selected.val = ''; //sets radio button for cc as unclicked
          //   $scope.$apply(toStepOne);
          break;
        default:
        //   $log.warn('unexpected response type from payments sdk', result);
      }
    });
  };
};

export const getBillingAddress = (billingAccount) => {
  const insuredParty = billingAccount.partyInfo?.find(
    (party) => party.partyTypeCd === 'InsuredParty',
  );
  return (
    insuredParty?.addresses?.find((address) => address.addrTypeCd === 'InsuredBillingAddr') || {}
  );
};

export const getFirstName = (billingAccount) => {
  const insuredParty = billingAccount.partyInfo?.find(
    (party) =>
      party.partyTypeCd === 'InsuredParty' || party.partyTypeCd === 'StatementAccountParty',
  );
  return insuredParty?.nameInfo?.givenName || '';
};

export const getLastName = (billingAccount) => {
  const insuredParty = billingAccount.partyInfo?.find(
    (party) =>
      party.partyTypeCd === 'InsuredParty' || party.partyTypeCd === 'StatementAccountParty',
  );
  return insuredParty?.nameInfo?.surname || '';
};
