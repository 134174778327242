import styled from 'styled-components';
import Select from 'react-select';

export const AnonymousReportClaimsStepFourContainer = styled.div.attrs({
  id: 'AnonymousReportClaimsStepFourContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 3%;
    margin-right: 3%;
  }
`;

export const ClaimEventNameLabel = styled.div.attrs({
  id: 'claimEventNameLabel',
})`
  font-weight: 600;
  margin-top: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const AnonymousReportClaimsStepFourSubHeader = styled.h3.attrs({
  id: 'AnonymousReportClaimsStepFourSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AnonymousReportClaimsStepFourSubHeaderDescription = styled.h3.attrs({
  id: 'AnonymousReportClaimsStepFourSubHeadeDescription',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 1%;
  margin-bottom: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ReportClaimsTitleHeader = styled.h4.attrs({
  id: 'reportClaimsTitleHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin: 20px 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ReportClaimsAddressTitleHeader = styled(ReportClaimsTitleHeader).attrs({
  id: 'reportClaimsAddressTitleHeader',
})`
  margin-top: 0;
`;
export const ReportClaimsCountryTitleHeader = styled(ReportClaimsTitleHeader).attrs({
  id: 'reportClaimsCountryTitleHeader',
})`
  margin-top: 0;
`;
export const ReportClaimsFieldHeader = styled.h4.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 5px;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimCityStateContainer = styled.div.attrs({
  id: 'cityStateContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    row-gap: 16px;
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    grid-template-rows: 1fr;
  }
`;
export const ReportClaimZipCountyContainer = styled.div.attrs({
  id: 'zipCountyContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    row-gap: 16px;
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
    margin-bottom: 16px;
  }
`;
export const ReportClaimThreeFormContainerFullRow = styled.div.attrs({})`
  display: grid;
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin: 20px 0;
  }
`;
const StepThreeRow = styled.div.attrs({})`
  display: grid;
  @media (max-width: 599px) {
    margin-top: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    margin-top: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    margin-top: 16px;
  }
  @media (min-width: 1280px) {
    margin-top: 20px;
  }
`;
export const MobilePhoneRow = styled(StepThreeRow).attrs({
  id: 'mobilePhoneRow',
})`
  @media (max-width: 599px) {
    margin-top: 24px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    margin-top: 24px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    margin-top: 24px;
  }
  @media (min-width: 1280px) {
    margin-top: 24px;
  }
`;
export const HomePhoneRow = styled(StepThreeRow).attrs({
  id: 'homePhoneRow',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 699px) {
  }
  @media (min-width: 700px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const EmailRow = styled(StepThreeRow).attrs({
  id: 'emailRow',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 699px) {
  }
  @media (min-width: 700px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AnonymousReportClaimsStepFourField = styled.div.attrs({})`
  min-width: 200px;
`;

export const Spacer = styled.div.attrs({
  id: 'spacer',
})``;

export const LocationFieldsContainer = styled.div.attrs({})`
  margin-top: 20px;
`;
export const StateSelectComponent = styled.div.attrs({})`
  margin-bottom: 10px;
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const ZipErrorMessage = styled.span.attrs({})`
  color: red;
  margin: 0;
`;
export const BestWayToContactContainer = styled.div.attrs({
  id: 'bestWayToContactContainer',
})`
  margin: 20px 0;
`;
export const BestWayToContactSelect = styled(Select)`
  width: 50%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: 100%;
  }
`;
export const ErrorMessage = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: red;
`;
