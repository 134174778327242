// Viewport Sizing
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1280px
// xl, extra-large: 1920px
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TemplateTwoNav = styled.nav.attrs({
  id: 'templateTwoNavBar',
})`
  background-color: ${(props) => props.theme.primaryColor};
  display: grid;
  grid-template-areas: '. logo . . links';
  grid-template-columns: 1fr 1fr 4fr 4fr 6fr;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr 2fr 2fr 7fr;
  }
  @media (min-width: 1280px) {
  }
`;
export const LogoLink = styled(Link)`
  grid-area: logo;
`;
export const VerticalMenuLink = styled(Link)`
  color: #555;
  text-decoration: none;
  align-self: center;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  :hover {
    text-decoration: underline;
  }
`;
export const HomeLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'homeLinkVertical',
  to: '/dashboard',
})``;
export const OpenQuotesLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'openQuotesLink',
  to: '/openQuotes',
})``;

export const ReportClaimLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'reportClaimLink',
  to: '/reportClaim',
})``;

export const MakePaymentLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'makePaymentLink',
  to: '/makePayment',
})``;

export const PendingRequestLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'pendingRequestLink',
  to: '/pendingRequests',
})``;

export const IDCardsLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'idCardsLink',
  to: '/idCards',
})``;
export const CustomerInformationLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customerInformationLink',
  to: '/customerInformation',
})``;

export const ChangeMyCoverageLinkVertical = styled(VerticalMenuLink).attrs({
  id: 'changeMyCoverageLink',
  to: '/changeCoverage',
})``;
export const CustomHTML1LinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customPage1',
  to: '/customPage1',
})``;
export const CustomHTML2LinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customPage2',
  to: '/customPage2',
})``;

export const HorizontalIndividualMenuLink = styled(Link)`
  font-size: 1rem;
  color: white;
  text-decoration: none;
  justify-self: center;
  :hover {
    color: ${(props) => props.theme.gray};
  }
`;
export const HomeLinkHorizontal = styled(HorizontalIndividualMenuLink)`
  grid-area: home;
  line-height: 1.8;
`;
export const ProfileLinkHorizontal = styled(HorizontalIndividualMenuLink)`
  grid-area: profile;
  line-height: 1.8;
`;
export const NeedHelpLinkHorizontal = styled(HorizontalIndividualMenuLink)`
  grid-area: needhelp;
  line-height: 1.8;
`;
export const LogoutLinkHorizontal = styled.div.attrs({
  id: 'logoutLinkContainer',
})`
  grid-area: logout;
  line-height: 1.8;
  margin-right: 10%;
`;

export const TemplateLogo = styled.img.attrs({
  id: 'loggedInLogo',
})`
  width: auto;
  height: 50px;
  padding: 8% 0 5% 0;
`;
export const MenuLinks = styled.div.attrs({
  id: 'menuLinks',
})`
  color: white;
  grid-area: links;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 10%;
  font-size: 1rem;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const DashboardContentArea = styled.div.attrs({
  id: 'dashboardContentContainer',
})`
  background-color: white;
  grid-area: content;
  flex: 2;
  align-self: stretch;
`;
export const AnnouncementContentArea = styled.div.attrs({
  id: 'announcementContentContainer',
})`
  background-color: ${(props) => props.theme.primaryColor};
  grid-area: announcementBanner;
  flex-basis: 15%;
  align-self: stretch;
  @media (min-width: 900px) and (max-width: 1279px) {
    flex-basis: 65%;
  }
`;

export const VerticalMenu = styled.div.attrs({
  id: 'verticalMenu',
})`
  /* need 900 to 1280 to account for smaller left side menu  */
  background-color: white;
  grid-area: menu;
  display: flex;
  /* flex: 1; */

  flex-direction: column;
  align-self: stretch;
`;
export const MenuItems = styled.div.attrs({
  id: 'menuLinksContainer',
})`
  background-color: white;
  margin: 10% 10% 5% 10%;
  display: grid;
  row-gap: 16px;
  max-width: 185px;
  grid-template-columns: 1fr;
`;
export const AgentContainer = styled.div.attrs({
  id: 'agentContainer',
})`
  background-color: white;
  color: #555;
  width: 82%;
  word-break: break-word;
  grid-area: agentInformation;
`;
export const GetQuoteContainer = styled.div.attrs({
  id: 'getQuoteContainer',
})`
  background-color: white;
  color: #555;
  grid-area: getQuote;
  width: 86%;
  @media (max-width: 899px) {
    width: 100%;
  }
`;

export const VerticalMenuAndDashboardContainer = styled.div.attrs({
  id: 'verticalMenuAndDashboardContainer',
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  row-gap: 26px;
  column-gap: 30px;
  margin: 30px 80px 110px 30px;
  align-items: flex-start;
`;

export const VerticalMenuNewsAndDashboardContainer = styled.div.attrs({
  id: 'verticalMenuAnnouncementsAndDashboardContainer',
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  row-gap: 26px;
  column-gap: 30px;
  margin: 30px 30px 110px;
  align-items: flex-start;
  @media (min-width: 900px) and (max-width: 1279px) {
    column-gap: 10px;
    flex-wrap: wrap-reverse;
  }
`;
export const ContentLink = styled.a.attrs({
  id: 'customContentLinkTemplate2',
})`
  line-height: 1.8;
  text-decoration: none;
  color: #555;
  :hover {
    text-decoration: underline;
  }
`;
