import * as React from 'react';
import { connect } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import routes from '../../../../../routes/index';
import Billing from '../../billing/index';
import Policies from '../../policies/index';
import Claims from '../../claims/index';
import CombinePolicies from '../../combine-policies/index';
import NewsBanners from '../../../news-banners';

import GetQuote from '../../get-quote';
import MyAgent from '../../my-agent';
import {
  AgentContentAreaMobile,
  AnnouncementContentAreaMobile,
  MobileAgentAndNewsContainer,
} from './styles';
import { GetQuoteContainer } from '../desktop/styles';

export const Routes = (props) => {
  const { claimListAllowed, combinePoliciesAllowed, getQuoteAllowed } = props;
  return (
    <>
      {useRouteMatch('/dashboard') ? (
        <>
          <Billing />
          <Policies id="dashboardPolicies" />
          {claimListAllowed && <Claims />}
          {combinePoliciesAllowed && <CombinePolicies />}
          {props.isMobile ? (
            <>
              <MobileAgentAndNewsContainer>
                <AgentContentAreaMobile>
                  <MyAgent />
                </AgentContentAreaMobile>
                <AnnouncementContentAreaMobile>
                  <NewsBanners />
                </AnnouncementContentAreaMobile>
                {getQuoteAllowed && (
                  <GetQuoteContainer>
                    <GetQuote template="template2" />
                  </GetQuoteContainer>
                )}
              </MobileAgentAndNewsContainer>
            </>
          ) : null}
        </>
      ) : null}
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </>
  );
};
const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
};
Routes.propTypes = {
  claimListAllowed: PropTypes.bool,
  combinePoliciesAllowed: PropTypes.bool,
  getQuoteAllowed: PropTypes.bool,
  isMobile: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  claimListAllowed: state.configurationSlice.features.features.claimList,
  combinePoliciesAllowed: state.configurationSlice.features.features.combinePolicies,
  getQuoteAllowed: state.configurationSlice.features.features.cpPortalLinking,

});
export default connect(mapStateToProps)(Routes);
