import styled from 'styled-components';

export const MobileAgentAndNewsContainer = styled.div.attrs({
  id: 'mobileMainContainer',
})`
  margin-bottom: 150px;
`;

export const AgentContentAreaMobile = styled.div.attrs({
  id: 'agentContentAreaMobile',
})`
  background-color: white;
  grid-area: agentBanner;
  padding-top: 10px;
`;
export const AnnouncementContentAreaMobile = styled.div.attrs({
  id: 'announcementContentContainer',
})`
  background-color: ${props => props.theme.primaryColor};
  grid-area: announcementBanner;
`;