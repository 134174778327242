import React, { useEffect } from 'react';
import {
  ApplicationNumberContainer,
  ApplicationStatusContainer,
  Button,
  CarIcon,
  GetAQuoteButtonContainer,
  HomeIcon,
  IconContainer,
  InsuranceQuotesSubheader,
  LoaderMargin,
  NoQuotesMessage,
  OpenQuotesContainer,
  OpenQuotesContentContainer,
  OpenQuotesHeader,
  OpenQuotesHeaderContainer,
  OpenQuotesLeftRowContainer,
  OpenQuotesRightRowContainer,
  OpenQuotesRowContainer,
  PolicyTermContainer,
  PremiumAmountContainer,
  QuoteAndIconContainer,
  QuoteNumberContainer,
  QuoteTextContainer,
} from './styles';
import { completeQuote, getOpenQuotes } from './openQuotesApi';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Loader from '../../common/loader';
import GetQuoteModal from './getQuoteModal';
import { DISPLAY_DATE_FORMAT, SPI_DATE_FORMAT } from '../../common/constants';
import ApiError from '../../common/api-error';

export const OpenQuotes = (props) => {
  const { customerId, openQuotes, isLoading, errors, salesPortalUrl } = props;
  useEffect(() => {
    props.dispatch(getOpenQuotes(customerId));
  }, []);

  const handleCompleteQuoteButtonClick = (ref, type) => {
    if (type.toUpperCase() === 'QUICKQUOTE') {
      type = 'Quote';
    }
    props.dispatch(completeQuote(ref, type, salesPortalUrl));
  };

  return (
    <OpenQuotesContentContainer>
      <Helmet>
        <title>Open Quotes</title>
      </Helmet>
      <OpenQuotesHeaderContainer>
        <OpenQuotesHeader>Open Quotes</OpenQuotesHeader>
        <GetAQuoteButtonContainer>
          <GetQuoteModal />
        </GetAQuoteButtonContainer>
      </OpenQuotesHeaderContainer>
      {isLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : errors ? (
        <ApiError
          body="Unable to get open quotes at this time. Please try again later."
          variant="nomargin"
        />
      ) : (
        <OpenQuotesContainer>
          {openQuotes === undefined || openQuotes.length === 0 ? (
            <NoQuotesMessage>There are no open quotes available at this time.</NoQuotesMessage>
          ) : (
            openQuotes.map((quote) => {
              const application = quote.applicationMini;
              const basicPolicy = application.basicPolicy;
              const effectiveDt = basicPolicy.effectiveDt;
              const expirationDt = basicPolicy.expirationDt;
              const productType = basicPolicy.description === 'Personal Auto' ? 'Auto' : 'Home';

              return (
                <OpenQuotesRowContainer key={quote.ref}>
                  <OpenQuotesLeftRowContainer>
                    <QuoteAndIconContainer>
                      <IconContainer>
                        {productType === 'Auto' ? (
                          <CarIcon viewBox="3 17 53 34" />
                        ) : (
                          <HomeIcon viewBox="3 8 52 43" />
                        )}
                      </IconContainer>
                      <QuoteTextContainer>
                        <QuoteNumberContainer>
                          <InsuranceQuotesSubheader>
                            {productType} Insurance Quote
                          </InsuranceQuotesSubheader>
                          <ApplicationNumberContainer>
                            {application.applicationNumber}
                          </ApplicationNumberContainer>
                        </QuoteNumberContainer>
                        <PolicyTermContainer>
                          Policy Term {moment(effectiveDt).format(DISPLAY_DATE_FORMAT)} -{' '}
                          {moment(expirationDt).format(DISPLAY_DATE_FORMAT)}
                        </PolicyTermContainer>
                      </QuoteTextContainer>
                    </QuoteAndIconContainer>
                    <ApplicationStatusContainer>
                      {application.typeCd === 'Application'
                        ? 'Application under review'
                        : 'Application awaiting submission'}{' '}
                    </ApplicationStatusContainer>
                  </OpenQuotesLeftRowContainer>
                  <OpenQuotesRightRowContainer>
                    <PremiumAmountContainer>
                      {basicPolicy.finalPremiumAmt} /{' '}
                      {moment(expirationDt, SPI_DATE_FORMAT).diff(
                        moment(effectiveDt, SPI_DATE_FORMAT),
                        'months',
                      )}{' '}
                      months
                    </PremiumAmountContainer>
                    <div>Premium Estimate</div>
                    {basicPolicy.businessSourceCd === 'DirectPortal' && (
                      <Button
                        onClick={() => {
                          handleCompleteQuoteButtonClick(quote.ref, application.typeCd);
                        }}
                      >
                        Complete Quote
                      </Button>
                    )}
                  </OpenQuotesRightRowContainer>
                </OpenQuotesRowContainer>
              );
            })
          )}
        </OpenQuotesContainer>
      )}
    </OpenQuotesContentContainer>
  );
};

OpenQuotes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  openQuotes: PropTypes.array,
  productMap: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.string,
  salesPortalUrl: PropTypes.string,
};

const mapStateToProps = (state) => ({
  customerId: state.customerSlice.customer.systemId,
  openQuotes: state.openQuotesSlice.openQuotes,
  productMap: state.openQuotesSlice.productMap,
  isLoading: state.openQuotesSlice.isLoading,
  errors: state.openQuotesSlice.errors,
  salesPortalUrl: state.configurationSlice.features.salesPortalUrl,
  state,
});

export default connect(mapStateToProps)(OpenQuotes);
